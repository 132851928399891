<template>
  <div class="Code">
    <div class="codeW">
      <div class="djs_timer">
        有效期:<span>{{ timer }}</span
        >s
      </div>
      <div class="bgimg">
        <div id="qrcode"></div>
      </div>
      <div class="code_btn" @click="qrcode">刷新二维码</div>
      <img src="../assets/close.png" alt="" class="close" @click="IsCode" />
    </div>
  </div>
</template>

<script>
import QRcode from "qrcodejs2";
export default {
  props: {
    id: Number,
  },
  data() {
    return {
      timer: 0,
    };
  },
  mounted() {
    this.qrcode();
  },
  methods: {
    IsCode() {
      this.$emit("IsCode", "关闭");
    },
    setTimer() {
      if (this.timer > 0) {
        this.timer--;
        setTimeout(() => {
          this.setTimer();
        }, 1000);
      }
    },
    qrcode() {
      let _this = this;
      if(_this.timer>0){
        this.$notify({ type: 'warning', message: '还在有效期内，请勿重复刷新', duration: 2000 });
        return
      }
      this.$UtilsApi.showTicket(this.id, {}).then((res) => {
        if (res.data.code == 0) {
          res.data.data.order_id = _this.id;
          document.querySelector("#qrcode").innerHTML = "";
          new QRcode("qrcode", {
            width: _this._scale(130),
            height: _this._scale(130), // 高度
            text: JSON.stringify(res.data.data), // 二维码内容
            render: "canvas", // 设置渲染方式（有两种方式 table和canvas，默认是canvas）
            // background: '#f0f'
            // foreground: '#ff0'
          });
          _this.timer=60;
          _this.setTimer();
        } else {
          this.$notify({
            type: "warning",
            message: res.data.msg,
            duration: 2000,
          });
        }
      });
    },
  },
};
</script>
<style scoped>
#qrcode {
  width: 130rem;
  height: 130rem;
}
.Code {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.codeW {
  width: 281rem;
  height: 290rem;
  background-color: #ffffff;
  border-radius: 24rem;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 150rem;
  background-image: url("../assets/pop-bg.png");
  background-size: 100%;
  background-repeat: no-repeat;
  position: relative;
}
.codeW .bgimg {
  width: 170rem;
  height: 170rem;
  background-image: url("../assets/icon-sm.png");
  background-size: 100%;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
}
.djs_timer {
  width: 100%;
  position: absolute;
  text-align: center;
  top: 20rem;
  font-size: 15rem;
  font-weight: bold;
}
.djs_timer span {
  color: #d9001b;
}
.code_btn {
  width: 100rem;
  height: 30rem;
  background: #85befc;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 18rem;
  font-size: 14rem;
  color: #fff;
  border-radius: 6rem;
}
.close {
  width: 20rem;
  height: 20rem;
  position: absolute;
  bottom: -70rem;
}
</style>